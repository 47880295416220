import React, { PropsWithChildren, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Paper } from "@mui/material";
import { PasswordInput } from "../PasswordInput";
import { useForm } from "react-hook-form";
import { EmailInput } from "../EmailInput";
import { ErrorMessage } from "../ErrorMessage";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import { FHMessage } from "../FHMessage";
import FHAuthContainer from "../FHAuthContainer";
import FHCheckboxField from "../FHCheckboxField";

const useStyles = (hasError: boolean | undefined) =>
  makeStyles((theme) => ({
    root: {
      paddingBottom: theme.spacing(4),
      [theme.breakpoints.up("md")]: {
        paddingBottom: theme.spacing(2),
      },
      "& $paperRoot": {
        [theme.breakpoints.down("md")]: {
          padding: `0 !important`,
        },
      },
    },
    inputFields: {
      marginBottom: theme.spacing(2),
      minHeight: theme.spacing(6),
    },
    error: {
      color: theme.palette.error.dark,
    },
    errorMessage: {
      marginBottom: theme.spacing(2.5),
      borderRadius: theme.spacing(1),
    },
    paperRoot: {
      padding: theme.spacing(6),
      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(0),
        boxShadow: theme.shadows[0],
        marginTop: theme.spacing(2),
      },
      "&.MuiPaper-root": {
        [theme.breakpoints.down("md")]: {
          padding: 0,
        },
      },
    },
    loggedOutMessage: {
      ...(!hasError && { marginBottom: theme.spacing(4) }),
      borderRadius: theme.spacing(1),
      fontSize: theme.typography.body1.fontSize,
      "& + $errorMessage": {
        marginTop: theme.spacing(1),
      },
    },
    infoIcon: {
      height: theme.spacing(3),
      width: theme.spacing(3),
    },
  }))();

type ContentLink = {
  id: number;
  guidValue: string;
  url: string | null;
};

export type LoginFormFields = {
  email: string;
  password: string;
  rememberMe: boolean;
};

export type LoginProps = {
  title?: string;
  subtitle?: string;
  loginCTALabel?: string;
  disabled?: boolean;
  requestError?: string;
  onLoginAttempt?: (form: LoginFormFields) => void;
  forgotPasswordCTALabel?: string;
  forgotPasswordCTARef?: string | null;
  hasError?: boolean;
  onLinkClick?: () => void;
  onErrorSet?: () => void;
  loginFailedErrormessage?: string;
  loginEmailFieldLabel?: string;
  loginEmailFieldHelperText?: string;
  loginPasswordFieldLabel?: string;
  loginPasswordFieldHelpText?: string;
  emailFieldErrorMessage?: string;
  loginEmailFieldValidationFailureLabel?: string;
  loginPasswordValidationFailureLabel?: string;
  loggedOutMessage?: string;
  loginEmailFieldWatermarkText?: string;
  passwordFieldWatermarkText?: string;
  rememberMeChecked?: boolean;
  onRememberMeChecked?: (isRememberMeChecked: boolean) => void;
  rememberMeText?: string;
  rememberMeDetailsLabel?: string;
  rememberMeTandC?: JSX.Element;
};

export const Login: React.FC<LoginProps> = ({
  title,
  subtitle,
  loginCTALabel,
  disabled,
  hasError,
  onLoginAttempt,
  requestError,
  loginFailedErrormessage,
  forgotPasswordCTALabel,
  onLinkClick,
  forgotPasswordCTARef,
  loginEmailFieldHelperText,
  loginPasswordFieldHelpText,
  emailFieldErrorMessage,
  loginPasswordValidationFailureLabel,
  loggedOutMessage,
  loginEmailFieldWatermarkText,
  passwordFieldWatermarkText,
  rememberMeText,
  rememberMeChecked = false,
  onRememberMeChecked,
  rememberMeDetailsLabel,
  rememberMeTandC,
}: PropsWithChildren<LoginProps>) => {
  const classes = useStyles(hasError);

  const {
    control,
    handleSubmit,
    formState,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const onSubmit = (form: LoginFormFields) => {
    onLoginAttempt(form);
  };

  return (
    <div className={classes.root}>
      <FHAuthContainer
        {...{
          title,
          subtitle,
          disabled,
          onLinkClick,
        }}
        linkText={forgotPasswordCTALabel}
        linkUrl={forgotPasswordCTARef || undefined}
        submitBtnText={loginCTALabel}
        submitBtnDisabled={!formState.isValid}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Paper className={classes.paperRoot}>
          {loggedOutMessage && (
            <FHMessage
              icon={<ErrorOutline className={classes.infoIcon} />}
              className={classes.loggedOutMessage}
              message={loggedOutMessage}
            />
          )}
          {hasError && (
            <ErrorMessage
              message={loginFailedErrormessage || requestError}
              className={classes.errorMessage}
            />
          )}
          <EmailInput
            name={"email"}
            label={loginEmailFieldWatermarkText}
            control={control}
            rules={{
              required: true,
              pattern: /^\S+@\S+\.\S+$/,
            }}
            errorRef={!!errors?.email}
            onChange={(event) => setEmail(event.target.value)}
            defaultValue={email}
            validationFailure={emailFieldErrorMessage}
            className={classes.inputFields}
            errorClassName={classes.error}
            help={loginEmailFieldHelperText}
          />
          <PasswordInput
            name="password"
            label={passwordFieldWatermarkText}
            errorRef={!!errors?.password}
            control={control}
            rules={{
              required: true,
            }}
            onChange={(event) => setPassword(event.target.value)}
            defaultValue={password}
            help={loginPasswordFieldHelpText}
            validationFailure={loginPasswordValidationFailureLabel}
            className={classes.inputFields}
            errorClassName={classes.error}
          />
          {/* commented to temporarily disable this feature */}
          {/* {rememberMeText && (
            <FHCheckboxField
              name="rememberMe"
              label={rememberMeText}
              control={control}
              defaultValue={rememberMeChecked}
              detailsLabel={rememberMeDetailsLabel}
              detailsModalTitle={rememberMeText}
              detailsText={rememberMeTandC}
              customOnChange={onRememberMeChecked}
            />
          )} */}
        </Paper>
      </FHAuthContainer>
    </div>
  );
};
