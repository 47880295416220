import { makeStyles } from "@mui/styles";
import React, { useEffect, PropsWithChildren } from "react";

const useStyles = () =>
  makeStyles(() => ({
    scrollLocationReference: {
      position: "absolute",
      visibility: "hidden",
    },
  }))();

function isElementVisible(el: HTMLDivElement | null) {
  if (!el) {
    return false;
  }

  const rect = el?.getBoundingClientRect(); // Optional chaining for safety
  if (!rect) {
    return false;
  }

  const vWidth = window.innerWidth || document.documentElement.clientWidth;
  const vHeight = window.innerHeight || document.documentElement.clientHeight;

  const efp = (x: number, y: number) => document.elementFromPoint(x, y);

  // Return false if it's not in the viewport
  if (
    rect.right < 0 ||
    rect.bottom < 0 ||
    rect.left > vWidth ||
    rect.top > vHeight
  ) {
    return false;
  }

  // Return true if any of its four corners are visible
  return (
    el.contains(efp(rect.left, rect.top) as Node) ||
    el.contains(efp(rect.right, rect.top) as Node) ||
    el.contains(efp(rect.right, rect.bottom) as Node) ||
    el.contains(efp(rect.left, rect.bottom) as Node)
  );
}

export type ScrollHereIfNotVisibleOnRenderProps = {
  children?: any;
  scrollOnInitialRenderOnly?: boolean;
};

export const ScrollHereIfNotVisibleOnRender: React.FC<
  ScrollHereIfNotVisibleOnRenderProps
> = ({
  children,
  scrollOnInitialRenderOnly = true,
}: PropsWithChildren<ScrollHereIfNotVisibleOnRenderProps>) => {
  const ref = React.createRef<HTMLDivElement>();
  const hasRenderedAlready = React.useRef<boolean>(false);
  const classes = useStyles();

  useEffect(() => {
    if (
      ref.current &&
      !isElementVisible(ref.current) &&
      !hasRenderedAlready.current
    ) {
      ref.current.scrollIntoView({ behavior: "smooth" });
      if (scrollOnInitialRenderOnly) {
        hasRenderedAlready.current = true;
      }
    }
  });

  return (
    <>
      <div className={classes.scrollLocationReference} ref={ref} />
      {children && children}
    </>
  );
};
