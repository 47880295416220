import React, { PropsWithChildren, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Paper, Typography } from "@mui/material";
import { PasswordInput } from "../PasswordInput";
import { useForm } from "react-hook-form";
import { FieldError, FieldErrorsImpl } from "react-hook-form";
import { EmailInput } from "../EmailInput";
import { ErrorMessage } from "../ErrorMessage";
import { Search } from "@mui/icons-material";
import classnames from "classnames";
import { FHButton } from "../FHButton";
import FHAuthContainer from "../FHAuthContainer";
import FHInputField from "../FHInputField";
import FHCheckboxField from "../FHCheckboxField";
import { FHSelectDropdown, FHDropdownOption } from "../FHSelectDropdown";
import { CountryDropdown } from "../CountryDropdown";
import { useIsMobile } from "src/hooks/hooks";
import { useLoginSelector } from "@/store/selectors";

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    marginBottom: theme.spacing(1),
  },
  section: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(6),
    [theme.breakpoints.down("md")]: {
      padding: `0 !important`,
      boxShadow: theme.shadows[0],
      marginTop: theme.spacing(2),
    },
  },
  inputFields: {
    marginBottom: theme.spacing(2),
    minHeight: theme.spacing(6),
  },
  error: {
    color: theme.palette.error.dark,
  },
  toggleText: {
    textDecoration: "none",
    marginBottom: theme.spacing(1),
  },
  checkboxText: {
    fontWeight: "bold",
  },
  errorMessage: {
    marginTop: theme.spacing(2.5),
  },
  addressToggleText: {
    fontSize: 16,
    color: theme.palette.text.primary,
    lineHeight: "21px",
    marginTop: theme.spacing(1.5),
    textAlign: "center",
    textDecoration: "underline",
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.main,
    },
    "&:focus, &:active": {
      color: theme.palette.text.primary,
    },
  },
  postCodeInput: {
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: theme.spacing(8),
    },
  },
  endAdornmentContainer: () => ({
    border: `${theme.spacing(0.2)}px solid ${theme.palette.secondary.main}`,
    color: theme.palette.secondary.main,
    height: "100%",
    width: theme.spacing(18),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    minWidth: "auto !important",
    borderTopRightRadius: "inherit",
    borderBottomRightRadius: "inherit",
    borderTopLeftRadius: "unset",
    borderBottomLeftRadius: "unset",
    position: "absolute",
    right: 0,
    top: 0,
    zIndex: 10,
    "&:before": {
      borderRadius: "0 5px 5px 0",
      top: 0,
      bottom: 0,
    },
  }),
  hideFields: {
    display: "none",
  },
  checkBox: {
    "& span": {
      textTransform: "none",
    },
  },
}));

type AddressFromPostCode = {
  formatted_address: string[];
  thoroughfare: string;
  building_name: string;
  sub_building_name: string;
  sub_building_number: string;
  building_number: string;
  line_1: string;
  line_2: string;
  line_3: string;
  line_4: string;
  locality: string;
  town_or_city: string;
  county: string;
  district: string;
  country: string;
};

export type RegisterFormFields = {
  email: string;
  password: string;
  title?: string;
  forenames: string;
  telephone: string;
  address: {
    postCode: string;
    addressLine1: string;
    addressLine2?: string;
    addressLine3?: string;
    country: string;
  };
  surname: string;
  marketingEmailIndicator: boolean;
};

export type RegisterProps = {
  registerTitle?: string;
  registerIntro?: string;
  submitBtnText?: string | null;
  onSubmitBtnClick?: (form: RegisterFormFields) => void;
  requestError?: JSX.Element;
  postCodeLookup?: (postCode: string) => void;
  addressesFromPostCode?: AddressFromPostCode[];
  linkText?: string;
  onLinkClick?: () => void;
  countries?: string[];
  disabled?: boolean;
  loginTitle?: string;
  loginEmailFieldLabel?: string;
  loginEmailFieldHelpText?: string;
  loginEmailFieldValidationFailureLabel?: string;
  emailErrorLabel?: string | null;
  loginPasswordFieldLabel?: string;
  loginPasswordFieldHelpText?: string;
  loginPasswordValidationFailureLabel?: string;
  loginConfirmPasswordFieldLabel?: string;
  loginConfirmPasswordFieldHelpText?: string;
  loginConfirmPasswordValidationFailureLabel?: string;
  personalDetailsTitle?: string;
  personalDetailsTitleOptions?: FHDropdownOption<unknown>[];
  personalDetailsTitleFieldLabel?: string;
  personalDetailsTitleFieldHelpText?: string;
  personalDetailsTitleFieldValidationFailureLabel?: string;
  personalDetailsForenameFieldLabel?: string;
  personalDetailsForenameFieldHelpText?: string;
  personalDetailsForenameFieldValidationFailureLabel?: string;
  personalDetailsSurnameFieldLabel?: string;
  personalDetailsSurnameFieldHelpText?: string;
  personalDetailsSurnameFieldValidationFailureLabel?: string;
  personalDetailsTelephoneFieldLabel?: string;
  personalDetailsTelephoneFieldHelpText?: string;
  personalDetailsTelephoneFieldValidationFailureLabel?: string;
  personalDetailsPostcodeFieldLabel?: string;
  personalDetailsPostcodeFieldHelpText?: string;
  personalDetailsPostcodeValidationFailureLabel?: string;
  personalDetailsPostcodeSearchIcon?: JSX.Element;
  personalDetailsManualAddress?: string;
  personalDetailsAddressStreetLabel?: string;
  personalDetailsAddressStreetHelpText?: string;
  personalDetailsAddressStreetValidationFailureLabel?: string;
  personalDetailsAddressTownCityLabel?: string;
  personalDetailsAddressTownCityHelpText?: string;
  personalDetailsAddressTownCityValidationFailureLabel?: string;
  personalDetailsAddressCountyLabel?: string;
  personalDetailsAddressCountyHelpText?: string;
  personalDetailsAddressCountyValidationFailureLabel?: string;
  personalDetailsAddressCountryLabel?: string;
  personalDetailsAddressCountryValidationFailureLabel?: string;
  personalDetailsOptOutCheckBoxLabel?: string;
  personalDetailsOptOutCheckBoxDefaultChecked?: boolean;
  requiredValidationFailureLabel?: string;
  personalDetailsPostcodeDropDownLabel?: string;
  isBackLink?: boolean;
  backLinkLabel?: string;
  backLinkUrl?: string;
  onBackLinkClick?: () => void;
  selectedEmail?: string;
  findAddressLabel?: string;
  signUpWordingRebook?: boolean;
};

export const Register: React.FC<PropsWithChildren<RegisterProps>> = ({
  registerTitle,
  registerIntro,
  submitBtnText,
  onSubmitBtnClick,
  linkText,
  onLinkClick,
  postCodeLookup,
  addressesFromPostCode,
  requestError,
  countries = [],
  disabled = false,
  loginTitle,
  loginEmailFieldLabel,
  loginEmailFieldHelpText,
  loginPasswordFieldLabel,
  loginPasswordFieldHelpText,
  loginPasswordValidationFailureLabel,
  loginConfirmPasswordFieldLabel,
  loginConfirmPasswordFieldHelpText,
  loginConfirmPasswordValidationFailureLabel,
  personalDetailsTitleOptions,
  personalDetailsTitle,
  personalDetailsTitleFieldLabel,
  personalDetailsTitleFieldHelpText,
  personalDetailsTitleFieldValidationFailureLabel,
  personalDetailsForenameFieldLabel,
  personalDetailsForenameFieldHelpText,
  personalDetailsForenameFieldValidationFailureLabel,
  personalDetailsSurnameFieldLabel,
  personalDetailsSurnameFieldHelpText,
  personalDetailsSurnameFieldValidationFailureLabel,
  personalDetailsTelephoneFieldLabel,
  personalDetailsTelephoneFieldHelpText,
  personalDetailsTelephoneFieldValidationFailureLabel,
  personalDetailsPostcodeFieldLabel,
  personalDetailsPostcodeFieldHelpText,
  personalDetailsPostcodeValidationFailureLabel,
  personalDetailsPostcodeSearchIcon = <Search color={"inherit"} />,
  personalDetailsManualAddress,
  personalDetailsAddressStreetLabel,
  personalDetailsAddressStreetHelpText,
  personalDetailsAddressStreetValidationFailureLabel,
  personalDetailsAddressTownCityLabel,
  personalDetailsAddressTownCityHelpText,
  personalDetailsAddressTownCityValidationFailureLabel,
  personalDetailsAddressCountyLabel,
  personalDetailsAddressCountyHelpText,
  personalDetailsAddressCountyValidationFailureLabel,
  personalDetailsAddressCountryLabel,
  personalDetailsAddressCountryValidationFailureLabel,
  personalDetailsOptOutCheckBoxLabel,
  personalDetailsOptOutCheckBoxDefaultChecked,
  requiredValidationFailureLabel = "Field is Required",
  emailErrorLabel,
  personalDetailsPostcodeDropDownLabel,
  isBackLink,
  backLinkLabel,
  backLinkUrl,
  onBackLinkClick,
  selectedEmail,
  findAddressLabel,
  signUpWordingRebook,
}: PropsWithChildren<RegisterProps>) => {
  const classes = useStyles();

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    trigger,
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const [showManualAddressFields, setShowManualAddressFields] = useState(false);

  const [showPostalAddressFields, setShowPostalAddressFields] = useState(false);

  const defaultCountry = countries.find(
    (country) => country === "United Kingdom",
  );
  const isMobile = useIsMobile();
  const { isBookingJourney } = useLoginSelector();
  const showYourDetailsBelow = isMobile && isBookingJourney;
  const postCodeAddressOption = (address: AddressFromPostCode): string => {
    return address.formatted_address.filter((value) => value.length).join(", ");
  };

  const onSubmit = (form: any) => {
    const whiteSpaceCleanForm = { ...form };

    Object.keys(form).map((key) => {
      const formVal = form[key];
      if (typeof formVal === "string")
        whiteSpaceCleanForm[key] = whiteSpaceCleanForm[key].trim();
    });

    onSubmitBtnClick({
      marketingEmailIndicator: !whiteSpaceCleanForm.marketingEmailIndicator,
      address: {
        addressLine1: whiteSpaceCleanForm.addressLine1,
        addressLine2: whiteSpaceCleanForm.addressLine2,
        addressLine3: whiteSpaceCleanForm.addressLine3,
        country: whiteSpaceCleanForm.country,
        postCode: whiteSpaceCleanForm.postCode,
      },
      email: whiteSpaceCleanForm.email,
      forenames: whiteSpaceCleanForm.forenames,
      password: whiteSpaceCleanForm.password,
      surname: whiteSpaceCleanForm.surname,
      telephone: whiteSpaceCleanForm.telephone,
      title: whiteSpaceCleanForm.title,
    } as RegisterFormFields);
    // reset();
  };

  // Validation check on postcode & manual address
  useEffect(() => {
    setShowManualAddressFields(addressesFromPostCode?.length === 0);
    setShowPostalAddressFields(addressesFromPostCode?.length !== 0);
  }, [addressesFromPostCode]);

  const handlePostCodeAddressChange = (postCodeAddress: number) => {
    const selectedAddress: AddressFromPostCode = (addressesFromPostCode || [])[
      postCodeAddress
    ];

    const setFormValue = (name: string, value: any) =>
      setValue(name, value, { shouldValidate: true, shouldDirty: true });
    setFormValue(
      "addressLine1",
      [
        selectedAddress?.line_1,
        selectedAddress?.line_2,
        selectedAddress?.line_3,
      ]
        .filter((line) => line?.length)
        .join(", "),
    );
    setFormValue("addressLine2", selectedAddress?.town_or_city);
    setFormValue("addressLine3", selectedAddress?.county);
    setFormValue("country", defaultCountry);

    if (!selectedAddress.town_or_city || !selectedAddress.county) {
      setShowManualAddressFields(true);
    }
  };

  const handlePostCodeLookup = () => {
    const postCode = getValues("postCode");
    if (
      getValues("selectedPostCodeAddress") ||
      getValues("addressLine1") ||
      getValues("addressLine2") ||
      getValues("addressLine3")
    ) {
      setValue("selectedPostCodeAddress", "", {
        shouldValidate: false,
        shouldDirty: false,
      });
      setValue("addressLine1", "", {
        shouldValidate: false,
        shouldDirty: false,
      });
      setValue("addressLine2", "", {
        shouldValidate: false,
        shouldDirty: false,
      });
      setValue("addressLine3", "", {
        shouldValidate: false,
        shouldDirty: false,
      });
      setValue("country", defaultCountry, {
        shouldValidate: false,
        shouldDirty: false,
      });
    }
    postCode &&
      trigger(["postCode"]).then((isValid) => {
        if (!isValid) setShowPostalAddressFields(false);
        if ((!showManualAddressFields && isValid) || showManualAddressFields) {
          postCodeLookup(postCode);
        }
      });
  };

  const isFieldErrorRequired = (
    fieldError: FieldError | FieldErrorsImpl<any>,
  ): boolean => fieldError?.type === "required";
  const getFieldErrorMessage = (
    fieldError: FieldError | FieldErrorsImpl<any>,
    validationFailureLabel: string,
  ): string =>
    isFieldErrorRequired(fieldError)
      ? requiredValidationFailureLabel
      : validationFailureLabel;

  const postCodeSearchButton = (
    <FHButton
      fullWidth={false}
      fhStyle={"secondary"}
      className={classes.endAdornmentContainer}
      onClick={handlePostCodeLookup}
    >
      {personalDetailsPostcodeSearchIcon}
      {!!findAddressLabel ? findAddressLabel : " Find Address"}
    </FHButton>
  );

  const manualAddressToggle = (
    <div
      className={classes.addressToggleText}
      onClick={() => setShowManualAddressFields(true)}
      dangerouslySetInnerHTML={{
        __html: personalDetailsManualAddress,
      }}
    />
  );

  return (
    <FHAuthContainer
      {...{
        submitBtnText,
        linkText,
        onLinkClick,
        disabled,
      }}
      title={registerTitle}
      subtitle={registerIntro}
      onSubmit={handleSubmit(onSubmit)}
      isBackLink={isBackLink}
      backLinkLabel={backLinkLabel}
      backLinkUrl={backLinkUrl}
      onBackLinkClick={onBackLinkClick}
    >
      {!showYourDetailsBelow && (
        <Typography variant={"h3"} className={classes.sectionTitle}>
          {loginTitle}
        </Typography>
      )}
      {!showYourDetailsBelow && (
        <Paper className={classes.section}>
          <EmailInput
            name={"email"}
            label={loginEmailFieldLabel}
            control={control}
            rules={{
              required: true,
              pattern: /^\S+@\S+\.\S+$/,
            }}
            errorRef={!!errors?.email}
            defaultValue={selectedEmail || ""}
            validationFailure={getFieldErrorMessage(
              errors?.email,
              emailErrorLabel,
            )}
            className={classes.inputFields}
            errorClassName={classes.error}
            help={loginEmailFieldHelpText}
          />
          <PasswordInput
            name="password"
            label={loginPasswordFieldLabel}
            control={control}
            rules={{
              required: true,
              pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
            }}
            defaultValue={""}
            validationFailure={getFieldErrorMessage(
              errors?.password,
              loginPasswordValidationFailureLabel,
            )}
            help={loginPasswordFieldHelpText}
            errorRef={!!errors?.password}
            className={classes.inputFields}
            errorClassName={classes.error}
          />
          <PasswordInput
            name="confirmPassword"
            label={loginConfirmPasswordFieldLabel}
            control={control}
            rules={{
              required: true,
              validate: {
                match: (value: string) =>
                  value === getValues().password ||
                  loginConfirmPasswordValidationFailureLabel,
              },
            }}
            defaultValue={""}
            validationFailure={getFieldErrorMessage(
              errors?.confirmPassword,
              errors?.confirmPassword?.message as string,
            )}
            help={loginConfirmPasswordFieldHelpText}
            errorRef={!!errors?.confirmPassword}
            className={classes.inputFields}
            errorClassName={classes.error}
          />
        </Paper>
      )}
      <Typography variant={"h3"} className={classes.sectionTitle}>
        {personalDetailsTitle}
      </Typography>
      <Paper className={classes.section}>
        <FHSelectDropdown
          name="title"
          label={personalDetailsTitleFieldLabel}
          control={control}
          options={personalDetailsTitleOptions}
          validationFailure={personalDetailsTitleFieldValidationFailureLabel}
          defaultValue={""}
          errorRef={!!errors?.title}
          className={classes.inputFields}
          errorClassName={classes.error}
          help={personalDetailsTitleFieldHelpText}
          fhStyle="information"
        />
        <FHInputField
          name="forenames"
          label={personalDetailsForenameFieldLabel}
          control={control}
          rules={{
            required: true,
            pattern: /^[a-zA-Z]([\w -]*[a-zA-Z])?\s*$/,
          }}
          noOutlineHighlight={true}
          defaultValue={""}
          errorRef={!!errors?.forenames}
          validationFailure={getFieldErrorMessage(
            errors?.forenames,
            personalDetailsForenameFieldValidationFailureLabel,
          )}
          className={classes.inputFields}
          errorClassName={classes.error}
          help={personalDetailsForenameFieldHelpText}
        />
        <FHInputField
          name="surname"
          label={personalDetailsSurnameFieldLabel}
          control={control}
          rules={{
            required: true,
            pattern: /^[a-zA-Z]([\w -]*[a-zA-Z])?\s*$/,
          }}
          noOutlineHighlight={true}
          defaultValue={""}
          errorRef={!!errors?.surname}
          validationFailure={getFieldErrorMessage(
            errors?.surname,
            personalDetailsSurnameFieldValidationFailureLabel,
          )}
          className={classes.inputFields}
          errorClassName={classes.error}
          help={personalDetailsSurnameFieldHelpText}
        />
        <FHInputField
          name="telephone"
          label={personalDetailsTelephoneFieldLabel}
          control={control}
          rules={{
            required: true,
            pattern: /^[0-9]*$/,
          }}
          noOutlineHighlight={true}
          defaultValue={""}
          errorRef={!!errors?.telephone}
          validationFailure={getFieldErrorMessage(
            errors?.telephone,
            personalDetailsTelephoneFieldValidationFailureLabel,
          )}
          className={classes.inputFields}
          errorClassName={classes.error}
          help={personalDetailsTelephoneFieldHelpText}
        />

        <FHInputField
          name="postCode"
          label={personalDetailsPostcodeFieldLabel}
          control={control}
          rules={{
            required: true,
            pattern:
              /^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$/,
          }}
          noOutlineHighlight={true}
          defaultValue=""
          errorRef={!!errors?.postCode}
          className={classnames([classes.inputFields, classes.postCodeInput])}
          validationFailure={getFieldErrorMessage(
            errors?.postCode,
            personalDetailsPostcodeValidationFailureLabel,
          )}
          errorClassName={classes.error}
          help={personalDetailsPostcodeFieldHelpText}
          endAdornment={postCodeSearchButton}
        />

        {!showManualAddressFields &&
          showPostalAddressFields &&
          !!addressesFromPostCode?.length && (
            <FHSelectDropdown
              name="selectedPostCodeAddress"
              label={personalDetailsPostcodeDropDownLabel}
              control={control}
              defaultValue={""}
              options={addressesFromPostCode.map((address, index) => ({
                label: postCodeAddressOption(address),
                value: index.toString(),
              }))}
              onDropdownChange={(event) =>
                handlePostCodeAddressChange(+(event.target.value as string))
              }
              className={classes.inputFields}
              errorRef={!!errors?.addressesFromPostCode}
              fhStyle="information"
            />
          )}

        {!showManualAddressFields && manualAddressToggle}
        <div
          className={classnames({
            [classes.hideFields]: !showManualAddressFields,
          })}
        >
          <FHInputField
            name="addressLine1"
            label={personalDetailsAddressStreetLabel}
            control={control}
            rules={{
              required: true,
            }}
            noOutlineHighlight={true}
            defaultValue=""
            errorRef={!!errors?.addressLine1}
            className={classes.inputFields}
            errorClassName={classes.error}
            help={personalDetailsAddressStreetHelpText}
            validationFailure={getFieldErrorMessage(
              errors?.addressLine1,
              personalDetailsAddressStreetValidationFailureLabel,
            )}
          />
          <FHInputField
            name="addressLine2"
            label={personalDetailsAddressTownCityLabel}
            rules={{
              required: true,
            }}
            noOutlineHighlight={true}
            control={control}
            defaultValue={""}
            errorRef={!!errors?.addressLine2}
            className={classes.inputFields}
            errorClassName={classes.error}
            help={personalDetailsAddressTownCityHelpText}
            validationFailure={getFieldErrorMessage(
              errors?.addressLine2,
              personalDetailsAddressTownCityValidationFailureLabel,
            )}
          />
          <FHInputField
            name="addressLine3"
            label={personalDetailsAddressCountyLabel}
            rules={{
              required: true,
            }}
            noOutlineHighlight={true}
            control={control}
            defaultValue={""}
            errorRef={!!errors?.addressLine3}
            className={classes.inputFields}
            errorClassName={classes.error}
            help={personalDetailsAddressCountyHelpText}
            validationFailure={getFieldErrorMessage(
              errors?.addressLine3,
              personalDetailsAddressCountyValidationFailureLabel,
            )}
          />
          <CountryDropdown
            name="country"
            label={personalDetailsAddressCountryLabel}
            control={control}
            rules={{
              required: true,
            }}
            defaultValue={defaultCountry}
            errorRef={!!errors?.country}
            errorClassName={classes.error}
            className={classes.inputFields}
            countries={countries}
            validationFailure={getFieldErrorMessage(
              errors?.country,
              personalDetailsAddressCountryValidationFailureLabel,
            )}
            fhStyle="information"
          />
        </div>
      </Paper>
      {showYourDetailsBelow && (
        <Typography variant={"h3"} className={classes.sectionTitle}>
          {loginTitle}
        </Typography>
      )}
      {showYourDetailsBelow && (
        <Paper className={classes.section}>
          <EmailInput
            name={"email"}
            label={loginEmailFieldLabel}
            control={control}
            rules={{
              required: true,
              pattern: /^\S+@\S+\.\S+$/,
            }}
            errorRef={!!errors?.email}
            defaultValue={selectedEmail || ""}
            validationFailure={getFieldErrorMessage(
              errors?.email,
              emailErrorLabel,
            )}
            className={classes.inputFields}
            errorClassName={classes.error}
            help={loginEmailFieldHelpText}
          />
          <PasswordInput
            name="password"
            label={loginPasswordFieldLabel}
            control={control}
            rules={{
              required: true,
              pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
            }}
            defaultValue={""}
            validationFailure={getFieldErrorMessage(
              errors?.password,
              loginPasswordValidationFailureLabel,
            )}
            help={loginPasswordFieldHelpText}
            errorRef={!!errors?.password}
            className={classes.inputFields}
            errorClassName={classes.error}
          />
          <PasswordInput
            name="confirmPassword"
            label={loginConfirmPasswordFieldLabel}
            control={control}
            rules={{
              required: true,
              validate: {
                match: (value: string) =>
                  value === getValues().password ||
                  loginConfirmPasswordValidationFailureLabel,
              },
            }}
            defaultValue={""}
            validationFailure={getFieldErrorMessage(
              errors?.confirmPassword,
              errors?.confirmPassword?.message as string,
            )}
            help={loginConfirmPasswordFieldHelpText}
            errorRef={!!errors?.confirmPassword}
            className={classes.inputFields}
            errorClassName={classes.error}
          />
        </Paper>
      )}
      <FHCheckboxField
        name="marketingEmailIndicator"
        label={
          signUpWordingRebook
            ? "Tick the box if you do not want to receive the latest offers and promotions by email, including 15% off your next break"
            : personalDetailsOptOutCheckBoxLabel
        }
        control={control}
        defaultValue={personalDetailsOptOutCheckBoxDefaultChecked}
        className={classes.checkBox}
      />
      {requestError && (
        <ErrorMessage
          className={classes.errorMessage}
          componentMessage={requestError}
        />
      )}
    </FHAuthContainer>
  );
};
