import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import SemanticTheme from "../../themes/SemanticTheme";
import { makeHtml } from "../../components/Utils";
import { RichContentArea } from "../../components/SemanticTheme/RichContentArea";
import { Register } from "../../components/SemanticTheme/Register";
import { FHDropdownOption } from "../../components/SemanticTheme/FHSelectDropdown";
import { registrationCountries } from "../../constants/registrationCountries";
import { createRegister, getPostCodeAddresses } from "@/store/actions";
import { usePostCodeSelector, useRegisterSelector } from "@/store/selectors";
import { LoginRegisterPage } from "src/graphql/generated-strapi/types";

export const makeRegisterCmsData = (content: any) => ({
  personalDetailsTitleList: [
    { value: "Mr", label: "Mr" },
    { value: "Mrs", label: "Mrs" },
    { value: "Miss", label: "Miss" },
    { value: "Ms", label: "Ms" },
    { value: "Mx", label: "Mx" },
    { value: "Dr", label: "Dr" },
    { value: "Prof", label: "Prof" },
    { value: "Other", label: "Other" },
  ],
  personalDetailsAddressCountryList: registrationCountries,
});
export type RegisterCmsData = ReturnType<typeof makeRegisterCmsData>;

export const RegisterComponent: React.FC<
  { content: LoginRegisterPage } & {
    overrideContinueCTALabel?: string;
  } & {
    backLinkLabel?: string;
    onBackLinkClick?: () => void;
    isBackLink?: boolean;
    selectedEmail?: string;
    experiment?: boolean;
    signUpWordingRebook?: boolean;
  }
> = (props): JSX.Element => {
  // CMS
  const {
    content,
    overrideContinueCTALabel,
    isBackLink,
    backLinkLabel,
    onBackLinkClick,
    selectedEmail,
    experiment,
    signUpWordingRebook,
  } = props;
  const registerCmsData = makeRegisterCmsData(content);
  const titles: FHDropdownOption<unknown>[] =
    registerCmsData.personalDetailsTitleList;
  // Component State
  const [error, setError] = useState(false);

  // Local State (Redux)
  const dispatch = useDispatch();
  const { loading: registerLoading, errorMessage } = useRegisterSelector();

  const { data: postCodeData, loading: postCodeLoading } =
    usePostCodeSelector();

  // Effects
  useEffect(() => {
    if (!errorMessage) {
      setError(false);
    } else {
      setError(true);
    }
  }, [errorMessage]);

  return (
    <ThemeProvider theme={SemanticTheme}>
      <Register
        disabled={postCodeLoading || registerLoading}
        registerTitle={experiment ? "Register" : content?.RegisterTitle}
        personalDetailsTitleOptions={titles}
        registerIntro={experiment ? "" : content?.RegisterIntro}
        loginTitle={content?.LoginTitle}
        loginEmailFieldLabel={content?.LoginEmailFieldLabel}
        loginEmailFieldHelpText={content?.LoginEmailFieldHelpText}
        loginPasswordFieldLabel={content?.LoginPasswordFieldLabel}
        loginPasswordFieldHelpText={content?.LoginPasswordFieldHelpText}
        loginConfirmPasswordFieldLabel={content?.LoginConfirmPasswordFieldLabel}
        loginConfirmPasswordFieldHelpText={
          content?.LoginConfirmPasswordFieldHelpText
        }
        loginConfirmPasswordValidationFailureLabel={
          content?.LoginConfirmPasswordValidationFailureLabel
        }
        personalDetailsTitle={content?.PersonalDetailsTitle}
        personalDetailsTitleFieldHelpText={
          content?.PersonalDetailsTitleFieldHelpText
        }
        personalDetailsTitleFieldLabel={content?.PersonalDetailsTitleFieldLabel}
        personalDetailsTitleFieldValidationFailureLabel={
          content?.PersonalDetailsTitleFieldValidationFailureLabel
        }
        personalDetailsForenameFieldLabel={
          content?.PersonalDetailsForenameFieldLabel
        }
        personalDetailsForenameFieldHelpText={
          content?.PersonalDetailsForenameFieldHelpText
        }
        personalDetailsSurnameFieldLabel={
          content?.PersonalDetailsSurnameFieldLabel
        }
        personalDetailsSurnameFieldHelpText={
          content?.PersonalDetailsSurnameFieldHelpText
        }
        personalDetailsTelephoneFieldLabel={
          content?.PersonalDetailsTelephoneFieldLabel
        }
        personalDetailsTelephoneFieldHelpText={
          content?.PersonalDetailsTelephoneFieldHelpText
        }
        personalDetailsPostcodeFieldLabel={
          content?.PersonalDetailsPostcodeFieldLabel
        }
        personalDetailsPostcodeFieldHelpText={
          content?.PersonalDetailsPostcodeFieldHelpText
        }
        personalDetailsPostcodeValidationFailureLabel={
          content?.PersonalDetailsPostcodeValidationFailureLabel
        }
        personalDetailsManualAddress={content?.PersonalDetailsManualAddress}
        personalDetailsAddressStreetLabel={
          content?.PersonalDetailsAddressStreetLabel
        }
        personalDetailsAddressStreetHelpText={
          content?.PersonalDetailsAddressStreetHelpText
        }
        personalDetailsAddressStreetValidationFailureLabel={
          content?.PersonalDetailsAddressStreetValidationFailureLabel
        }
        personalDetailsForenameFieldValidationFailureLabel={
          content?.PersonalDetailsForenameFieldValidationFailureLabel
        }
        personalDetailsSurnameFieldValidationFailureLabel={
          content?.PersonalDetailsSurnameFieldValidationFailureLabel
        }
        personalDetailsTelephoneFieldValidationFailureLabel={
          content?.PersonalDetailsTelephoneFieldValidationFailureLabel
        }
        personalDetailsAddressTownCityLabel={
          content?.PersonalDetailsAddressTownCityLabel
        }
        personalDetailsAddressTownCityHelpText={
          content?.PersonalDetailsAddressTownCityHelpText
        }
        personalDetailsAddressTownCityValidationFailureLabel={
          content?.PersonalDetailsAddressTownCityValidationFailureLabel
        }
        personalDetailsAddressCountyLabel={
          content?.PersonalDetailsAddressCountyLabel
        }
        personalDetailsAddressCountyHelpText={
          content?.PersonalDetailsAddressCountyHelpText
        }
        personalDetailsAddressCountyValidationFailureLabel={
          content?.PersonalDetailsAddressCountyValidationFailureLabel
        }
        personalDetailsAddressCountryLabel={
          content?.PersonalDetailsAddressCountryLabel
        }
        personalDetailsAddressCountryValidationFailureLabel={
          content?.PersonalDetailsAddressCountryValidationFailureLabel
        }
        personalDetailsOptOutCheckBoxLabel={
          content?.PersonalDetailsOptOutCheckBoxLabel
        }
        personalDetailsOptOutCheckBoxDefaultChecked={
          content?.PersonalDetailsOptOutCheckBoxDefaultChecked
        }
        personalDetailsPostcodeDropDownLabel={
          content?.PersonalDetailsPostcodeDropDownLabel
        }
        requestError={
          error ? (
            <RichContentArea>
              <div
                dangerouslySetInnerHTML={makeHtml(
                  content?.AccountAlreadyExists as string,
                )}
              />
            </RichContentArea>
          ) : undefined
        }
        submitBtnText={overrideContinueCTALabel || content?.ContinueButtonLabel}
        onSubmitBtnClick={(form) => dispatch(createRegister(form))}
        countries={registerCmsData.personalDetailsAddressCountryList}
        postCodeLookup={(postCode) =>
          dispatch(getPostCodeAddresses({ postCode }))
        }
        addressesFromPostCode={postCodeData?.addresses}
        emailErrorLabel={content?.LoginEmailFieldValidationFailureLabel}
        isBackLink={isBackLink}
        backLinkLabel={backLinkLabel}
        onBackLinkClick={onBackLinkClick}
        selectedEmail={selectedEmail}
        findAddressLabel={content?.FindAddressLabel}
        signUpWordingRebook={signUpWordingRebook}
      />
    </ThemeProvider>
  );
};
